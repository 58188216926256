<template>
  <div class="palettes_panel">
    <div class="page-title">
      <h3>Palettes</h3>
    </div>

    <button class="aggregated_field_add_button" :class="openThis === 'Upload' ?'bg_2':''" @click="openThis = 'Upload'">Upload</button>
    <button class="aggregated_field_add_button" :class="openThis === 'Library'?'bg_2':''" @click="openThis = 'Library'">Library</button>
    <div style="margin-left: 10px; margin-top: 43px;" v-if="openThis === 'Upload'">
      <Palettes
        v-model="palettes"
      />
      <button class="aggregated_field_add_button bg_4"  style="margin-left: 0px;" @click="uploadToDatabase">Upload to DB</button>
    </div>
    <div style="margin-left: 10px;" v-if="openThis === 'Library'">
     <button :class="favoriteOnly?'bg_1':''" class="aggregated_field_add_button" style="margin-top: 10px; margin-left: -5px; width: 150px;" @click.prevent="favoriteOnly=!favoriteOnly">{{favoriteOnly?'Favorites only':'All'}}</button>
      <router-link :to="`/gen?d=${projId}`" class="black-text">
        <h2 style="display:flex;justify-content:space-around;" @click="setSelectedToProject">Project is {{projectName}}</h2>
      </router-link>

      <Palettes
        v-model="lpalettes"
        :modificator="'Libraries'"
        @setFavorites="setToFavorite($event)"
        :key="palettesRefresh"

      />
      <button class="bg_3 aggregated_field_add_button" style="margin-left: 0px;" @click="setSelectedToProject">Set {{lpalettes.filter(x=>x.selected===true).length}} selected to  {{projectName}}</button>
      <button class="bg_5 aggregated_field_add_button" style="margin-left: 10px;" @click="lpalettes.forEach(m=>m.selected=false)">Clear selection</button>
      <button class="bg_2 aggregated_field_add_button" style="margin-left: 10px;" @click="selectButtonPush">Select {{favoriteOnly?'favorites':'all'}}</button>

    </div>

  </div>
</template>
<script>
import Palettes from '@/components/sections/Palettes'

export default {
  components: {
    Palettes
  },
  data () {
    return {
      palettes: [],
      lpalettes: [],
      tags: [],
      openThis: 'Library',
      dselected: [],
      projectName: '',
      usedModules: [],
      favorites: [],
      palettesRefresh: false,
      favoriteOnly: false
    }
  },
  computed: {
    projId () {
      return this.$store.getters.lastProject
    }
  },

  watch: {
    lpalettes () {
      this.setFavorites()
    },
    favoriteOnly () {
      this.setFavorites()
    }
  },
  async mounted () {
    this.getLibraryData()
    await this.$store.dispatch('getArrayFromTableJson', { path: 'favoritePallets', user: false })
    if (this.$store.getters.data !== null) { this.favorites = this.$store.getters.data }
    this.setFavorites()
  },
  methods: {
    cl (data) {
      console.log(data)
    },
    async uploadToDatabase () {
      await this.$store.dispatch('saveChangesToThePalettes', { data: this.palettes, user: false })
      this.palettes = []
      await this.getLibraryData()
    },
    async setSelectedToProject () {
      const items = this.lpalettes.filter(item => item.selected === true)
      await this.$store.dispatch('setNewPalettes', { json: items, prid: Number(this.$store.getters.lastProject) })
      await this.getLibraryData()
    },
    async getLibraryData () {
      await this.$store.dispatch('fetchProjects')
      await this.$store.dispatch('getLastProject')
      await this.$store.dispatch('getThePalettesLibrary', false)
      this.lpalettes = this.$store.getters.library.palettes
      this.usedPalettes = await this.$store.dispatch('getUsedItems', { type: 'palettes', prid: this.$store.getters.lastProject })
      this.lpalettes.forEach(item => {
        if (this.usedPalettes.includes(item.indexer)) item.selected = true
      })
      const prjcts = JSON.parse(this.$store.getters.projects)
      this.projectName = prjcts.filter(f => Number(f.date) === Number(this.$store.getters.lastProject))[0].name
    },
    async setToFavorite (index) {
      const key = this.lpalettes[index].indexer
      if (!this.favorites.includes(key)) {
        this.$store.dispatch('putArrayToTable', { path: 'favoritePallets', array: [key], user: false })
        this.favorites.push(key)
      } else {
        this.$store.dispatch('deleteArrayItem', { path: 'favoritePallets', key: key, user: false })
        this.favorites.splice(this.favorites.indexOf(key), 1)
      }
      this.setFavorites()
    },
    setFavorites () {
      this.lpalettes.forEach(pal => {
        if (this.favorites.includes(pal.indexer)) {
          pal.favorite = true
          if (this.favoriteOnly) {
            pal.displayed = true
          }
        } else {
          pal.favorite = false
          if (this.favoriteOnly) {
            pal.displayed = false
          } else {
            pal.displayed = true
          }
        }
      })
      this.palettesRefresh = !this.palettesRefresh
    },
    selectButtonPush () {
      if (!this.favoriteOnly) {
        this.lpalettes.forEach(m => { m.selected = true })
      } else {
        this.lpalettes.forEach(m => {
          if (m.favorite) {
            m.selected = true
          } else {
            m.selected = false
          }
        })
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.keyListener, false)
  }
}
</script>
